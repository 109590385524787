import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// Routes
import * as routes from "routes/routes";
import CustomRoute from "routes/customRoute";

// Pages
import Login from "pages/login/Login";
import MyCoaching from "pages/MyCoaching/MyCoaching";
import CoachingProCoaching from "pages/CoachingProCoaching/CoachingProCoaching";
import CoachingProApp from "pages/CoachingProApp/CoachingProApp";
import InitialConsultation from "pages/InitialConsultation/InitialConsultation";
import CoachingPro from "pages/CoachingPro/CoachingPro";
import Success from "pages/Success/Success";
import Start from "pages/start/start";
import Registration from "pages/registration/Registration";
import Payment from "pages/payment/payment";
import NutritionCreation from "pages/nutritionCreation/NutritionCreation";
import ChangePassword from "pages/changePassword/ChangePassword";
import NutritionForm from "pages/nurtritionForm/NutritionForm";
import FormAdvantages from "pages/formAdvantages/FormAdvantages";
import NutritionRecipes from "pages/nutritionRecipes/NutritionRecipes";
import NutritionCalories from "pages/nutritionCalories/NutritionCalories";
import MacroNutrients from "pages/macroNutritients/MacroNutrients";
import ClientSuccessful from "pages/clientSuccessful/clientSuccessful";
import SelectCoach from "pages/selectCoach/selectCoach";
import OnlineKurs from "pages/OnlineKurs/OnlineKurs";
import OnlineKursStarten from "pages/OnlineKursStarten/OnlineKursStarten";

function App() {
  return (
    <Router>
      <Switch>
        <CustomRoute path={routes.LOGIN} exact component={Login} />
        <CustomRoute path={routes.MY_COACHING} exact component={MyCoaching} />
        <CustomRoute
          path={routes.INITIAL_CONSULTATION}
          exact
          component={InitialConsultation}
        />
        <CustomRoute path={routes.COACHING_PRO} exact component={CoachingPro} />
        <CustomRoute
          path={routes.COACHING_PRO_APP}
          exact
          component={CoachingProApp}
        />
        <CustomRoute
          path={routes.COACHING_PRO_COACHING}
          exact
          component={CoachingProCoaching}
        />
        <CustomRoute path={routes.SUCCESS} exact component={Success} />
        <CustomRoute path={routes.SUCCESS} exact component={Success} />
        <CustomRoute path={routes.FOODIARY_APP} exact component={Start} />
        <CustomRoute
          path={routes.REGISTRATION}
          exact
          component={Registration}
        />
        <CustomRoute path={routes.PAYMENT} exact component={Payment} />
        <CustomRoute
          restricted
          path={routes.NUTRITION_CREATION}
          exact
          component={NutritionCreation}
        />
        <CustomRoute
          path={routes.CHANGE_PASSWORD}
          exact
          component={ChangePassword}
        />
        <CustomRoute
          restricted
          path={routes.NUTRITION_FORM}
          exact
          component={NutritionForm}
        />
        <CustomRoute
          restricted
          path={routes.NUTRITION_FORM_ADVANTAGES}
          exact
          component={FormAdvantages}
        />
        <CustomRoute
          restricted
          path={routes.NUTRITION_RECIPES}
          exact
          component={NutritionRecipes}
        />
        <CustomRoute
          restricted
          path={routes.NUTRITION_CALORIES}
          exact
          component={NutritionCalories}
        />
        <CustomRoute
          restricted
          path={routes.NUTRITION_MACRO_NUTRIENTS}
          exact
          component={MacroNutrients}
        />
        <CustomRoute
          path={routes.CLIENT_SUCCESSFUL}
          exact
          component={ClientSuccessful}
        />
        <CustomRoute path={routes.SELECT_COACH} exact component={SelectCoach} />
        <CustomRoute path={routes.ONLINE_KURS} exact component={OnlineKurs} />
        <CustomRoute
          path={routes.ONLINE_KURS_STARTEN}
          exact
          component={OnlineKursStarten}
        />
        {/* <CustomRoute
            restricted
            path={routes.ROOT}
            exact
            component={<div>Root</div>}
          /> */}
        <Route
          exact
          path="/"
          render={() => <Redirect to={routes.MY_COACHING} />}
        />
        <Route path="*" render={() => "Route Not Found"} />
      </Switch>
    </Router>
  );
}

export default App;
